import { combineReducers } from 'redux';

import * as actions from '../actions/actions';
import { loadingBarReducer } from 'react-redux-loading-bar'

const initialUiState = {
    // "isFirebaseLoading": 0,
    // "firebaseGotAllData": true,
    // "firebaseSavedAllData": true,
    // "firebaseErrorStatus": false,
    // "firebaseError": null,
    "authReady": false,
    // "gotIssueCount": 0
}

function ui(state = initialUiState, action) {
    switch (action.type) {
        case actions.SET_AUTH_SESSION:
            return {...state, firebaseGotAllData: false}
        case actions.AUTH_READY:
            return {...state, authReady: true}



        // case actions.GETTING_FIREBASE_DATA:
        //     return {...state, isFirebaseLoading: state.isFirebaseLoading+1, firebaseGotAllData: false}
        // case actions.GOT_FIREBASE_DATA:
        //     return {...state, isFirebaseLoading: state.isFirebaseLoading-1}
        // case actions.GOT_ALL_FIREBASE_DATA:
        //     return {...state, firebaseGotAllData: true}

        // case actions.SAVING_TO_FIREBASE:
        //     return {...state, isFirebaseLoading: state.isFirebaseLoading+1, firebaseSavedAllData: false}
        // case actions.SAVED_TO_FIREBASE:
        //     return {...state, isFirebaseLoading: state.isFirebaseLoading-1}
        // case actions.SAVED_ALL_TO_FIREBASE:
        //     return {...state, firebaseSavedAllData: true}

        
        
        

        
        // case actions.ERROR_FROM_FIREBASE:
        //     return {...state, isFirebaseLoading: state.isFirebaseLoading-1, firebaseErrorStatus: true, firebaseError: action.error}
        
        case "LOGOUT":
            return initialUiState
        default:
            return state
    }
}


function user(state = {}, action) {
    switch (action.type) {
        case actions.SET_AUTH_SESSION:
            return {...state, authUser: action.user}
        
            
        
        case actions.SAVE_USER_LOCALLY:
            return {...state, userData: {...state.userData, ...action.userData}}

        // case actions.FINISHED_SETUP:
        //     window.scrollTo(0,0)
        //     return {...state, userData: {...state.userData, NEW: false}}


        case "LOGOUT":
            return {}
        default:
            return state
    }
}

// const { 'open': parentValue, ...noChild } = state;
// // Remove the 'doc_id' from the 'open' element
// const { [action.doc_id]: removedValue, ...childWithout } = parentValue;
// // Merge back together
// const withoutEle = { ...noChild, 'open': childWithout };
// return withoutEle;









const rootReducer = combineReducers({
    ui,
    user,
    loadingBar: loadingBarReducer,
  })

// const rootReducer = (previousState, action) => previousState;

export default rootReducer