
import { put, select, delay, cancel, take, call, 
    fork, takeLatest, takeEvery, takeLeading } from 'redux-saga/effects'

import {getUserDataFromEmail, doSignOut, createRegisterUserKey, createUserData, saveUserData
    } from '../../firebase'




import initiateListeners from '../../firebase/listeners'

// import { GOT_FIREBASE_DATA, GETTING_FIREBASE_DATA } from '../actions/actions'

import { AUTH_READY, SET_AUTH_SESSION, SAVE_USER_LOCALLY, NEW_SIGN_UP } from '../actions/actions'


export const authSagas = [
    takeLeading(SET_AUTH_SESSION, getLoggedInUserData),
    takeEvery("LOGOUT", doSignOut),
    takeLatest(NEW_SIGN_UP, signUpNewUser),
]




function* signUpNewUser(action) {
    const data = action.userData

    const registerDoc = yield call(createRegisterUserKey, { "email": data.email });

    const userData = { doc_id: registerDoc.id, 
        email: data.email, 
        fname: data.fname,
        lname: data.lname,

        // NEW_CLIENT: true, // to send a different welcome email to user
        
        // SIGNED_UP_WITH: data.app,
       
        NEW_QID: true,
        NEW_PILOT: true,
        NEW_DEX: true,

        QID_USER: false, // when user enters company name, changes to true and if user is invited

        type: "ADMIN", 
        // DELETED: false, 
        // client: data.client_name, 
        vendors_assigned: "ALL",
        projects_assigned: ["ALL"],
        date_modified: parseInt(new Date().valueOf()),
        date_created: new Date()
    }
    
    yield call(createUserData, registerDoc.id, userData);
    action.callback()
}




function* getLoggedInUserData(action) {
    const authUser = action.user;

    // yield put({type: GETTING_FIREBASE_DATA})
    try {

        if (authUser.email === "ops@yelloskye.com") {
            yield put({type: AUTH_READY});
            return
        }

        const userDataDocs = yield call(getUserDataFromEmail, authUser.email)
        console.log("AUTH: userDataDocs docs", userDataDocs.docs)

        if (userDataDocs.docs.length === 0) { throw new Error('USER EMAIL NOT FOUND'); }

        const userData = userDataDocs.docs[0].data()
        let newUser = false

        if (!userData.uid) {
            newUser = true
            yield call(saveUserData, userData.doc_id, {uid: authUser.uid});
            yield put({ type: SAVE_USER_LOCALLY, userData: {uid: authUser.uid, ...userData} });
        } else {
            yield put({ type: SAVE_USER_LOCALLY, userData: userData });
        }

        console.log("AUTH: Initiating Listeners")
        yield call(initiateListeners)
       

        console.log("AUTH: WAITING TO LOG IN")
        const task = yield fork(watchIncomingData);
        yield take(AUTH_READY)
        yield cancel(task);

        // if (newUser) {
        //     if (userData.SIGNED_UP_WITH === "QID") {
        //         window.location.href = "/qid"
        //     } else if (userData.SIGNED_UP_WITH === "PILOT") {
        //         window.location.href = "/dex/pilot"
        //     } else if (userData.SIGNED_UP_WITH === "DEX") {
        //         window.location.href = "/dex/client"
        //     } else {
        //         window.location.href = "/"
        //     }
        // } else {
        //     // window.location.href = "/"
            
        //     // else {window.location.href="/"}
        // }

        if (action.callback) {action.callback()}
        
        
      }

    catch(error) {
        alert("ERROR logging in, please contact YelloSKYE", error)
        console.log("ERROR GETTING USER DATA", error)
        doSignOut()
        // window.location.href = "/login"
      } 
}




function* handleLoginReady({ input }) {
    // debounce by 500ms
    yield delay(750)
    yield put({type: AUTH_READY});
    
}
  
function* watchIncomingData() {
    // will cancel current running handleLoginReady task
    yield takeLatest('INCOMING_DATA', handleLoginReady);
}

// function* watchIncomingStorageData() {
//     // will cancel current running handleLoginReady task
//     yield takeLatest('ADD_CLIENT_STORAGE_SIZE', handleLoginReady);
// }





