import React, {useEffect, useState} from 'react';
import logo from './logo.png';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";



import TopLoadingBar from './components/TopLoadingBar'
import { connect } from 'react-redux'

const Signup = React.lazy(() => import('./pages/Signup'));
const Register = React.lazy(() => import('./pages/Register'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));

const Dashboard = React.lazy(() => import('./DashboardContainer'));

function App() {

  return (<Router>
    <React.Suspense fallback={<TopLoadingBar />}>
      <Switch>    

        <Route path={`/qid`} name="QID" render={props => null}/>
        <Route path={`/dex`} name="DEX" render={props => null}/>
        <Route path={`/d3d`} name="D3D" render={props => null}/>


        {/* <Route exact path={`/new`} name="New Client" render={props => 
            <New {...props}/>}/> */}
        <Route exact path={`/signup`} name="New Client" render={props => 
            <Signup {...props}/>}/>
        
        
        <Route path={`/register`} name="Register" render={props => 
            <Register {...props}/>}/>

        <Route exact path={`/forgot-password`} render={props => 
            <ForgotPassword {...props}/>}/>


        <Route path={`/`} render={props => <Dashboard {...props} /> } />

        
          
      </Switch>
    </React.Suspense>
    </Router>

    
  );
}

export default App;

// const mapStateToProps = (state, ownProps) => {
//   // console.log(state.AllIssues)

//   return ({
//     // ... computed data from state and optionally ownProps

//     authReady: state.ui.authReady
    
//   })
// }

//   const mapDispatchToProps = {
//     setAuthSession
//   }
  
  
//   export default connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(App)