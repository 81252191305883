import { all } from 'redux-saga/effects'



import { authSagas } from './auth';
// import { firebaseDataSagas } from './firebase';



export const getLoggedInUserDocId = state => state.user.userData.doc_id
export const getLoggedInUserUid = state => state.user.authUser.uid



export default function* rootSaga() {
yield all([
    ...authSagas,
    // ...firebaseDataSagas
])
}