import {store} from '../index';
import {db} from '.' 




  // *** Listeners ***

  let userListener = () => {}; 
 

  export default () => {

    userListener();

    userListener = db.collection("Users")
    .where("uid", "==", store.getState().user.userData.uid)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {
      
      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {

        store.dispatch({type: "SAVE_USER", userData: change.doc.data()})

          // if (store.getState().user.userData.doc_id === change.doc.id) {
          //   store.dispatch({type: "SAVE_USER", userData: change.doc.data()})
          // }

          // if (change.type === "added") {
          //     store.dispatch({type: "SAVE_LOCAL_USER", doc_id: change.doc.id, data: change.doc.data()})
          //     // console.log("New city: ", change.doc.data());
          // }
          // if (change.type === "modified") {
          //     store.dispatch({type: "SAVE_LOCAL_USER", doc_id: change.doc.id, data: change.doc.data()})
          //     // console.log("Modified city: ", change.doc.data());
          // }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_USER", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }


      });
    });



    
}