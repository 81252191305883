/*
 * action types
 */
export const LOGOUT = 'LOGOUT'


export const NEW_SIGN_UP = 'NEW_SIGN_UP'
export const EXISTING_SIGN_UP = 'EXISTING_SIGN_UP'



export const SET_AUTH_SESSION = 'SET_AUTH_SESSION'

export const AUTH_READY = 'AUTH_READY'



export const SAVE_USER_LOCALLY = 'SAVE_USER'


// triggers firebase event to modify Users
export const SAVE_ALL_USER_DATA = 'SAVE_ALL_USER_DATA'
export const CREATE_ALL_USER_DATA = 'CREATE_ALL_USER_DATA'





// firebase listener calls action to save changes locally
export const SAVE_LOCAL_USER = 'SAVE_LOCAL_USER'
export const REMOVE_LOCAL_USER = 'REMOVE_LOCAL_USER'




// uses isFirebaseLoading counter
export const SAVING_TO_FIREBASE = 'SAVING_TO_FIREBASE'
export const SAVED_TO_FIREBASE = 'SAVED_TO_FIREBASE'
export const SAVED_ALL_TO_FIREBASE = 'SAVED_ALL_TO_FIREBASE'
// uses isFirebaseLoading counter
export const GETTING_FIREBASE_DATA = 'GETTING_FIREBASE_DATA'
export const GOT_FIREBASE_DATA = 'GOT_FIREBASE_DATA'
export const GOT_ALL_FIREBASE_DATA = 'GOT_ALL_FIREBASE_DATA'


export const ERROR_FROM_FIREBASE = 'ERROR_FROM_FIREBASE'









