import firebase from "firebase/app";
import {store} from '../index';


import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);


export default firebase;


export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();


export const createPaymentOrder = firebase.functions().httpsCallable('createPaymentOrder');






const USERS_COLLECTION = "users" 
const REGISTER_IDS_COLLECTION = "register_ids"
const UNIQUE_CLIENT_COLLECTION = "Client"


  

// *** Auth API ***
export const doCreateUserWithEmailAndPassword = (email, password) =>
auth.createUserWithEmailAndPassword(email, password);

export const doSignInWithEmailAndPassword = (email, password) =>
auth.signInWithEmailAndPassword(email, password);

export const doSignOut = () => {
  auth.signOut();
  // window.location.href="https://www.yelloskye.com/qid"
}

export const doSendPasswordResetEmail = (email) =>
auth.sendPasswordResetEmail(email);




export const checkRegistrationKey = (key) =>
  db.collection(REGISTER_IDS_COLLECTION).doc(key).get()




export const createNewClientData = (data) => {
  db.collection(UNIQUE_CLIENT_COLLECTION).doc(data.client_name).set(data)
}

// For NEW CLIENT SIGNUP
export const getDocByClientName = (clientName) => {
    return db.collection(UNIQUE_CLIENT_COLLECTION)//.doc(clientName)
    .where("client_name", '==',clientName)
    .where("PAID",'==', true)
    .limit(1)
    .get()
  }
  export const getDocsWithEmailToCheckExistance = (email) => {
    return db.collection(USERS_COLLECTION)
    .where("email", "==", email)
    .limit(1).get()
  }

export const updateClientOrderIdInfo = (clientName, order_id) => {
    return db.collection(UNIQUE_CLIENT_COLLECTION).doc(clientName).update({"orderId": order_id})
  }




  
// For USER AUTH  
export const getUserDataFromEmail = (email) => 
    firebase.firestore().collection(USERS_COLLECTION).where("email", "==", email).limit(1).get()

    

export const getUserDataFromEmailNClient = (email, client) => 
    firebase.firestore().collection(USERS_COLLECTION)
    .where("email", "==", email)
    .where("client", "==", client)
    .where("DELETED", "==", false)
    .limit(1).get()



export const saveUserData = (doc_id, userData) =>
    firebase.firestore().collection(USERS_COLLECTION).doc(doc_id).update(userData)


export const createRegisterUserKey = (data) =>
db.collection(REGISTER_IDS_COLLECTION).add(data)

export const saveRegisterUserKeyInfo = (doc_id, data) =>
db.collection(REGISTER_IDS_COLLECTION).doc(doc_id).update(data)

export const createUserData = (doc_id, userData) =>
db.collection(USERS_COLLECTION).doc(doc_id).set(userData)
  
  